<template>
    <div class="page-content">
        <img src="@/assets/images/login.png" class="login-img" alt="" />
        <div class="main-wrap">
            <p class="company-text">案件查询</p>

            <el-form
                ref="form2"
                :model="form2"
                :rules="loginRules2"
                auto-complete="on"
                label-position="right"
                class="form-content center"
            >
                <el-form-item prop="idCard" class="flex item-info center">
                    <span class="title-text">身份证号</span>
                    <el-input
                        v-model="form2.idCard"
                        placeholder="输入被申请人身份证号查询调解案件"
                    />
                </el-form-item>
                <el-form-item prop="phone" class="flex item-info">
                    <span class="title-text">手机号码</span>
                    <el-input
                        v-model="form2.phone"
                        placeholder="输入被申请人手机号查询调解案件"
                        oninput="if(value.length>11)value=value.slice(0,11)"
                        type="number"
                        tabindex="1"
                        auto-complete="on"
                    />
                </el-form-item>
                <!-- <el-form-item
                        prop="isShow"
                        class="v-button hand flex"
                        :class="form2.isShow == 3 ? 'high-v-botton' : ''"
                    >
                        <img
                            class="icon"
                            v-show="form2.isShow != 3"
                            @click.stop="showOpen"
                            src="@/assets/login/tips.png"
                        />
                        <span
                                v-show="form2.isShow != 3"
                                class="svg-container"
                                @click.stop="showOpen"
                            >
                                <svg-icon
                                    icon-class="round"
                                    style="color: #007af5"
                                />

                            </span>
                        <span
                            v-show="form2.isShow == 3"
                            class="svg-container svg-color"
                        >
                            <svg-icon icon-class="adopt" />
                        </span>
                        <span
                            class="flex1"
                            style="margin-left: 10px; font-size: 16px"
                            @click.stop="showOpen"
                            >{{
                                form2.isShow == 3
                                    ? "验证成功"
                                    : "点击按钮进行验证"
                            }}</span
                        >
                        <Vcode
                            :show="form2.isShow == 2"
                            @success="success"
                            @fail="fails"
                            @close="closeRe"
                        />
                    </el-form-item> -->
                <el-form-item prop="verifyCode" class="flex v-code">
                    <span class="title-text">验证码</span>
                    <el-input
                        ref="vcode"
                        v-model="form2.verifyCode"
                        placeholder="手机验证码"
                        oninput="if(value.length>6)value=value.slice(0,6)"
                        name="verifyCode"
                        type="number"
                        tabindex="1"
                        auto-complete="on"
                    />
                    <el-button
                        class="vcode-text"
                        :disabled="disabled"
                        @click.stop="sendVCode"
                        >{{ valiBtn }}</el-button
                    >
                </el-form-item>
                <!-- <el-form-item class="flex item-info">
                 
                   
                </el-form-item>  -->
                <!-- 勾选同意欢太个人隐私保护指引 -->
                <p class="check-wrap flex" >
                    <el-checkbox v-model="checked"
                        >勾选同意获取手机号并发送验证码</el-checkbox
                    >
                </p>
                <el-button
                    class="btn-wrap"
                    :loading="loading"
                    type="success"
                    @click="handleLogin2"
                    >查询</el-button
                >
            </el-form>
        </div>
    </div>
</template>
<script>
//  import uuidv1 from 'uuid/v1'
// import uuidv1 from "vue-uuid";
import uuidv1 from "vue-uuid";
// import { JSEncrypt } from "jsencrypt";
import JSEncrypt from "encryptlong";
export default {
    name: "case",

    data() {
        const validateIsShow = (rule, value, callback) => {
            if (value == 1) {
                callback(new Error("请先完成验证"));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            valiBtn: "获取验证码",
            checked: true,
            disabled: false,
            photoHua: 0, // 图片滑动验证的错误次数
            form2: {
                phone: "",
                idCard: "",
                verifyCode: "",
                isShow: 1, // 1未验证，2验证中，3验证通过
            },
            loginRules2: {
                phone: [
                    //     {
                    //         required: true,
                    //         trigger: "blur",
                    //         validator: validatePhone,
                    //     },
                    {
                        required: true,
                        trigger: "blur",
                        message: "手机号码不能为空",
                    },
                ],
                verifyCode: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "验证码不能为空",
                    },
                ],
                idCard: [
                    // {
                    //     required: true,
                    //     trigger: "blur",
                    //     validator: validateVcode,
                    // },
                    {
                        required: true,
                        trigger: "blur",
                        message: "身份证号不能为空",
                    },
                ],
            },
        };
    },
    created() {
        // this.getValidateCode();
    },
    methods: {
        // 发送短信  获取验证码 并只验证手机号 是否正确
        sendVCode() {
            const validateFieldList = [];
            this.$refs["form2"].validateField(["phone"], (err) => {
                // 部分字段校验
                if (!err) {
                    validateFieldList.push(err);
                    if (
                        validateFieldList.length == 1 &&
                        validateFieldList.every((item) => item === "")
                    ) {
                        this.tackBtn(); // 验证码倒数60秒
                        this.getValidCode(this.form2.phone); // 获取短信验证码接口
                    } else {
                        return false;
                    }
                }
            });
        },
        // 验证码倒数60秒
        tackBtn() {
            // console.log("倒计时");
            let time = 60;
            const timer = setInterval(() => {
                if (time == 0) {
                    // console.log("倒计时--获取验证码", time);
                    clearInterval(timer);
                    this.valiBtn = "获取验证码";
                    this.disabled = false;
                } else {
                    this.disabled = true;
                    this.valiBtn = time + "秒后重试";
                    time--;
                    // console.log("倒计时--time", time);
                }
            }, 1000);
        },
        // 获取短信验证码接口
        async getValidCode(phone) {
            let publicKey =
                "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYZe+7dlcf7379zgYR6FynP63w9KN6CYpjbWwMSv4Mtiw2zxGcaxXuPI2U9Q5suR7XJK8XcGurwL8fDgZGgft2yzZNmFaDHuTV6UxSVKtwWq/8zc+IjRgumQBUzXzwYEQ5Eiblkur0bPJLnsVxnn+1jjEKPYbLjHA9Ig28Xw/BNQIDAQAB";
            let jse = new JSEncrypt();
            console.log("jse", jse);
            jse.setPublicKey(publicKey); // 加入rsa public key encrypt encryptLong

            let str = `phone=${phone}&nonstr=${Date.parse(
                new Date()
            )}&timestamp=${Date.parse(new Date())}`;

            let phone1 = jse.encryptLong(str);

            const params = {
                encrypt: phone1,
            };
            const rsp = await this.$http("getValidCode", params);
            if (rsp.code != 200) {
                return this.$message.error(rsp.message);
            }
            this.$message.success("已发送至手机");
        },
        // 短信登录  通过验证后，手动隐藏模态框
        success() {
            this.form2.isShow = 3;
            this.$refs["form2"].validateField(["isShow"], (err) => {});
        },
        // 失败的回调，失败的逻辑是，用户失败指定次数之后，加入弹窗提示信息
        fails() {
            this.photoHua += 1;
            if (this.photoHua > 5) {
                this.$message({
                    message:
                        "其实ψ(*｀ー´)ψ......你要是一直拼不成功，我会让你一直拼下去的哟...",
                    type: "warning",
                });
            }
        },
        // 点击图片验证的背景部分事件
        closeRe() {
            this.$message({
                message:
                    "请完成滑动验证，验证成功之后验证框会自动消失...(๑╹◡╹)ﾉ",
                type: "warning",
            });
        },
        // 短信登录按钮 
        handleLogin2() {
          
            this.$refs["form2"].validate((valid) => {
                // 为表单绑定验证功能
                if (valid) {
                    if (!this.checked) {
                        return this.$message({
                            message: "请勾选同意获取手机号并发送验证码",
                            type: "warning",
                        });
                    }
                    this.loginUrl2();
                } else {
                    return false;
                }
            });
        },
        async loginUrl2() {
            let params = {
                idCard: this.form2.idCard,
                phone: this.form2.phone,
                validateCode: this.form2.verifyCode,
            };
            this.loading = true;
            const rsp = await this.$http("caseInfoSearchlog", params);
            this.loading = false;
            if (rsp.code != 200) {
                return this.$message({ message: rsp.message, type: "warning" });
            }
            localStorage.setItem("validateCode", params.validateCode);
            let searchFlag = rsp.data.searchFlag;
            this.$router.push({
                path: "/case/list",  
                query: { searchFlag: searchFlag },
            });
        },
    },
};
</script>
<style lang="less" scoped>
.page-content {
    width: 100%;
    height: 800px;

    .login-img {
        width: 100%;
        height: 100%;
    }

    .main-wrap {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
        transform: translate(-50%, -50%);
        width: 560px;
        border-radius: 10px;
        -webkit-box-shadow: 0 0 9px 1px rgba(6, 0, 1, 0.2);
        box-shadow: 0 0 9px 1px rgba(6, 0, 1, 0.2);
        // height: 424px;
        height: 474px;
        background-color: hsla(0, 0%, 100%, 0.85);
        .company-text {
            color: #4d4d4d;
            font-size: 22px;
            letter-spacing: 0.5px;
            text-align: center;
            padding: 67px 0 47px;
        }

        .el-form {
            flex-direction: column;
            .icon {
                width: 24px;
                height: 24px;
                margin: 0 0 0 20px;
            }
            .item-info {
                .el-input {
                    height: 36px;
                    line-height: 36px;
                    width: 280px;
                }
            }
            .title-text {
                width: 75px;
                display: inline-block;
            }

            .v-code {
                .el-input {
                    width: 180px;
                }
                .vcode-text {
                    width: 92px;
                    height: 36px;
                    outline: none;
                    color: #db3a3a;
                    margin-left: 8px;
                    padding: 0;
                }
            }

            .check-wrap {
                width: 355px;
                margin: 22px 0;
            }
            // 登录按钮
            .btn-wrap {
                width: 280px;
                height: 36px;
                background: #db3a3a;
                font-size: 16px;
                outline: none;
                border: 0;
            }
        }
    }

    ::v-deep .el-checkbox__inner,
    .el-checkbox__inner {
        // 设置position 使得 子元素不与其产生新的层叠关系
        border-color: #db3a3a;
    }
    ::v-deep .el-checkbox__label {
        // color: #db3a3a;
        color: #4d4d4d;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #db3a3a;
    }
    ::v-deep .el-checkbox__inner:hover {
        border-color: #db3a3a;
    }
}
</style>
